<script setup lang="ts">
const emits = defineEmits(['update:modelValue']);
const props = defineProps({
  label: {
    type: String,
  },
  modelValue: {
    type: Boolean,
  },
  activeText: {
    type: String,
    default: '',
  },
  inactiveText: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
  },
});

const value = ref(props.modelValue);
watch(
  () => value.value,
  (val) => {
    emits('update:modelValue', val);
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div class="base-switch flex flex-col">
    <label class="font-medium" v-if="props.label"
      >{{ label }} <span v-if="props.required">*</span>
    </label>
    <div class="flex items-center justify-center gap-2">
      <slot name="inactive"></slot>
      <el-switch
        v-model="value"
        size="large"
        :active-text="props.activeText"
        :inactive-text="props.inactiveText"
      >
      </el-switch>
      <slot name="active"></slot>
    </div>
  </div>
</template>
